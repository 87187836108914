@import "./variables.scss";

.carousel-control-prev-icon{
    color: #000;
}

@media (max-width: 768px) {
    .ng-image-slider{
         height: 300px !important;
    }
    .ng-image-slider-container > div {
      height: 300px !important; /* Adjust height for mobile */
    }
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle{
    background-color:#0056b3 !important;
  }
.modal-xl{
    max-width: 100% !important;
}

.category-input{
    cursor: pointer;
}

button{
    background: #0056b3;
    color: white; 
    cursor: pointer;
    
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s ease; 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}


@media only screen and (max-width: 768px) {
    .section-box{
        padding: 0px !important;
    }
  }

/* Remove the default outline on the button */
button:focus {
    outline: none;
}

/* You can also remove the outline on button click by modifying the active state */
button:active {
    outline: none;
}

body{
    margin: 0;
    width: 100%;
    min-height: 100%;
    color: #000;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: $Navy;
    line-height: 1.3;
    font-family: 'Amaranth', sans-serif !important;
    font-weight: 300;
}
.section-box{
    padding: 80px 0;
}
.section{
    background: $Navy;
}

.code-font{

.section-title{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 10px 0 40px;
    width: 100%;
    white-space: nowrap;
    font-size: 25px;
    font-weight: 600;
    color: $LightestSlate;

    margin: 0 0 10px 0;

    .n-section-title{
        margin-right: 10px;
        font-weight: normal;
        color: $Green;
        font-size: 20px;
        position: relative;
        bottom: 4px;
    }

    &:after{
        content: '';
        display: block;
        height: 1px;
        width: 300px;
        background-color: $LightestNavy;
        position: relative;
        top: -5px;
        margin-left: 20px;
    }
}
.buttonon{
    background: #0056b3;
    color: white; 
    font-weight: bolder;
    border: none;
    padding: 10px 30px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px; 
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s ease; 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}



.btn btn-outline-primary{
   color: #ffffff !important;
   
    border: none;
  padding:10px 20px !important;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); 
  :hover {
    /* Adjust the shadow on hover if desired */
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7);
  }

}

a{
    text-decoration: none;

    &:hover{
        text-decoration: none;
        color: $Green;
    }
}

.underline {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: $Green !important;
}
.underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 3px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: $Green;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0%;
}
.underline:hover:after {
  width: 100%;
  left: 0;
}

@media (min-width: 768px) and (max-width: 1100px){
    .proyect-container{
        margin: 30px 0 !important;
        height: 385px !important;
    }

}
@media (min-width: 1100px) and (max-width: 1300px){
    .container{
        max-width: 950px;
    }
}

@media (min-width: 992px) and (max-width: 1100px){
    .container{
        max-width: 800px;
    }

}

@media (min-width: 880px) and (max-width: 992px){
    .container{
        max-width: 700px;
    }
}

@media (min-width: 768px) and (max-width: 880px){
    .container{
        max-width: 600px;
    }
}

@media (max-width: 768em){
    .section-title {
        font-size: 24px;
    }
    .container {
        width: 90%;
    }
}


@media (max-width: 1000px){
    .banner h2, .banner h3, .big-title{
        font-size: 70px !important;
    }
    .big-subtitle{
        font-size: 14px !important;
    }
}

@media (max-width: 768px){
    .banner h2, .banner h3, .big-title{
        font-size: 60px !important;
    }

}
@media (max-width: 480px){
    .banner h2, .banner h3, .big-title{
        font-size: 50px !important;
    }

}
@media (max-width: 376px){
    .banner h2, .banner h3, .big-title{
        font-size: 40px !important;
    }
}


@media (max-width: 30em){
    body {
        font-size: 18px;
    }
}




/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

  
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
